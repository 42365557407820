import React, {
  useEffect, useState, useContext, useRef,
} from 'react';
import { Button, Spacer } from '@nextui-org/react';
import { Alert, Snackbar } from '@mui/material';

import AuthContext from '@/auth/context';
import useFetch from '@/hooks/useFetch';

import styles from './index.module.css';

export const COMPONENT_TYPE = {
  Image: 'IMAGE',
  Text: 'TEXT',
  Video: 'VIDEO',
  Group: 'GROUP',
  SVG: 'SVG',
};

const filterComponentsByType = (components: any, type: string | string[]) => {
  const comps: any[] = [];

  components?.forEach((component: any) => {
    if (Array.isArray(type) && type.includes(component.type)) {
      comps.push(component);
    } else if (component.type === type) {
      comps.push(component);
    } else if (component.type === COMPONENT_TYPE.Group) {
      const groupComps: any[] = filterComponentsByType(component.components, type);
      comps.push(...groupComps);
    }
  });

  return comps;
};

const getUpdatedComponents = (
  components: any,
  textComponentsById: any,
  imageComponentsById: any,
) => {
  const comps: any[] = [];

  components?.forEach((component: any) => {
    if (component.type === COMPONENT_TYPE.Text) {
      const updatedComponent = textComponentsById[component.id];
      component.text = updatedComponent.text;
      comps.push(component);
    } else if (
      component.type === COMPONENT_TYPE.Image
      || component.type === COMPONENT_TYPE.SVG
      || component.type === COMPONENT_TYPE.Video
    ) {
      comps.push(imageComponentsById[component.id]);
    } else if (component.type === COMPONENT_TYPE.Group) {
      component.components = [
        ...getUpdatedComponents(
          component.components,
          textComponentsById,
          imageComponentsById,
        ),
      ];
      comps.push(component);
    } else {
      comps.push(component);
    }
  });

  return comps;
};

export default function EditSlideItemProduct({
  slide, index, activeIndex, onDelete, onSave, updateVideo, fetchContent,
  isProcessing, setIsProcessing, unhydratedSlideLayout, screenplaySlide,
  showSlideEditingModal, setEditSlideIndex, onDuplicateSlide, showChatEditOption, seekToSlide,
}: {
  slide: any;
  index: number;
  activeIndex: number;
  onSave: (slide: any, updatedScreenplay: any, index: number) => void;
  onDelete: (index: number) => void;
  updateVideo: any;
  fetchContent: any;
  isProcessing: boolean;
  setIsProcessing: (val: boolean) => void;
  unhydratedSlideLayout: any;
  screenplaySlide: any;
  showSlideEditingModal: boolean;
  setEditSlideIndex: any;
  onDuplicateSlide: any;
  seekToSlide: (index: number) => void;
  showChatEditOption: boolean;
}) {
  const authContext = useContext(AuthContext);
  const [save, setSave] = useState<boolean>(false);
  const [chatPrompt, setChatPrompt] = useState<string>('');
  const [openEditImageModal, setOpenEditImageModal] = useState(false);
  const [editImageItem, setEditImageItem] = useState<any>({});
  const [textComponents, setTextComponents] = useState<any[]>([]);
  const [imageComponents, setImageComponents] = useState<any[]>([]);
  const [voiceover, setVoiceover] = useState<string>(screenplaySlide.voiceover || '');
  const [isSnackbarVisible, setIsSnackbarVisible] = useState<boolean>(false);
  const [isMediaLoading, setIsMediaLoading] = useState<boolean>(false);
  const [useLocalMedia, setUseLocalMedia] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({});
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const firstRender = useRef(true);
  const renderCount = useRef(0);

  const [glow, setGlow] = useState<any>(false);

  useEffect(() => {
    setGlow(activeIndex === index);
  }, [activeIndex]);

  const editableImageComponents = imageComponents.filter((item) => item.editable !== false);

  const { fetchData: downloadImage } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/images/`,
    { method: 'POST' },
  );

  const initialiseComponents = () => {
    setTextComponents(filterComponentsByType(slide.components, COMPONENT_TYPE.Text));
    setImageComponents([
      ...filterComponentsByType(slide.components, COMPONENT_TYPE.Image),
      ...filterComponentsByType(slide.components, COMPONENT_TYPE.SVG),
      ...filterComponentsByType(slide.components, COMPONENT_TYPE.Video),
    ]);
  };

  useEffect(() => {
    initialiseComponents();
  }, [slide, index]);

  const getComponentToAttributeMap = (
    components: any[],
    componentIdToAttributeMap: Map<string, string>,
  ) => {
    components?.forEach((component: any) => {
      if (component.type === COMPONENT_TYPE.Group) {
        getComponentToAttributeMap(component.components, componentIdToAttributeMap);
      } else if (component.type === COMPONENT_TYPE.Text) {
        if (component.text && component.text.length > 0) {
          if (component.text[0] === '{' || component.text[-1] === '}') {
            componentIdToAttributeMap.set(component.id, component.text.slice(1, -1));
          }
        }
      } else if (
        component.type === COMPONENT_TYPE.Image
        || component.type === COMPONENT_TYPE.Video
        || component.type === COMPONENT_TYPE.SVG
      ) {
        if (component.src[0] === '{' || component.src[-1] === '}') {
          componentIdToAttributeMap.set(component.id, component.src.slice(1, -1));
        }
      }
    });
  };

  const getUpdatedAttributesMap = (
    components: any[],
    componentIdToAttributeMap: Map<string, string>,
    updatedAttributesMap: Map<string, string>,
  ) => {
    components?.forEach((component: any) => {
      if (component.type === COMPONENT_TYPE.Group) {
        getUpdatedAttributesMap(
          component.components,
          componentIdToAttributeMap,
          updatedAttributesMap,
        );
      } else if (componentIdToAttributeMap.has(component.id)) {
        if (component.type === COMPONENT_TYPE.Text) {
          updatedAttributesMap.set(componentIdToAttributeMap.get(component.id)!, component.text);
        } else if (
          component.type === COMPONENT_TYPE.Image
          || component.type === COMPONENT_TYPE.Video
          || component.type === COMPONENT_TYPE.SVG
        ) {
          updatedAttributesMap.set(componentIdToAttributeMap.get(component.id)!, component.src);
        }
      }
    });
  };

  const getUpdatedScreenplayAttributes = (updatedSlideComponents: any) => {
    const componentIdToAttributeMap = new Map<string, string>();
    getComponentToAttributeMap(unhydratedSlideLayout.components, componentIdToAttributeMap);

    const updatedAttributesMap = new Map<string, string>();
    getUpdatedAttributesMap(
      updatedSlideComponents,
      componentIdToAttributeMap,
      updatedAttributesMap,
    );

    return updatedAttributesMap;
  };

  const onSaveSlide = () => {
    if (screenplaySlide.voiceover !== voiceover) {
      setIsSnackbarVisible(true);
    }

    const textComponentsById = textComponents.reduce(
      (prev: any, cur: any) => ({ ...prev, [cur.id]: cur }),
      {},
    );
    const imageComponentsById = imageComponents.reduce(
      (prev: any, cur: any) => ({ ...prev, [cur.id]: cur }),
      {},
    );
    const updatedSlideComponents = getUpdatedComponents(
      slide.components,
      textComponentsById,
      imageComponentsById,
    );

    const updatedScreenplayAttributes = getUpdatedScreenplayAttributes(updatedSlideComponents);
    const updatedScreenplay = {
      ...screenplaySlide,
      attributes: {
        ...Object.fromEntries(updatedScreenplayAttributes),
      },
      voiceover,
    };

    onSave(
      {
        ...slide,
        components: [...updatedSlideComponents],
        voiceover,
      },
      updatedScreenplay,
      index,
    );
  };

  useEffect(() => {
    if (save) {
      onSaveSlide();
      setSave(false);
    }
  }, [
    textComponents,
    imageComponents,
    voiceover,
    save,
  ]);

  useEffect(() => {
    if (firstRender.current) {
      renderCount.current += 1;

      if (renderCount.current >= 3) {
        firstRender.current = false;
      }
    }
  }, [slide, textComponents]);

  const onSetTextChange = (componentIndex: number, value: string) => {
    const cloneTexts = [...textComponents];

    const clonedComponent = { ...cloneTexts[componentIndex] };
    clonedComponent.text = value;

    cloneTexts[componentIndex] = clonedComponent;

    setTextComponents(cloneTexts);
    setSave(true);
  };

  const onSetVoiceover = (val: string) => {
    setVoiceover(val);
    setSave(true);
  };

  const onOpenEditImage = (
    componentId: string,
    src: string,
    componentType: string,
    mediaIndex?: number,
  ): void => {
    setOpenEditImageModal(true);
    setEditImageItem({
      componentId,
      src,
      componentType,
      mediaIndex,
    });
    setSelectedFiles([]);
  };

  const toggleModal = () => setOpenEditImageModal(!openEditImageModal);

  const openChatModal = () => {
    setEditSlideIndex(index);
  };

  const closeChatModal = () => {
    setEditSlideIndex(null);
  };

  const handleMediaUpload = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFiles([file]);
      setUseLocalMedia(true);
      const currFormData = new FormData();
      currFormData.append('file', file);
      setFormData(currFormData);
    }

    event.target.value = '';
  };

  const onSaveEditImage = () => {
    setIsUploading(true);

    setUploadProgress(0);
    uploadingFilesPhase();

    const interval = setInterval(() => {
      setUploadProgress((prevProgress) => {
        if (prevProgress < 100) {
          return prevProgress + 1;
        }
        clearInterval(interval);
        return 100;
      });
    }, 50);
  };

  const uploadingFilesPhase = async () => {
    const token = await authContext.currentUser?.getIdToken();
    if (!token) return;

    setIsMediaLoading(true);

    let mediaSource = editImageItem.src;

    if (useLocalMedia) {
      const uploadResponse = await fetch(
        `${import.meta.env.VITE_BACKEND_URL}/api/v1/upload/`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        },
      ).then((res) => res.json());
      mediaSource = uploadResponse.data.url;
    } else {
      const downloadResponse = await downloadImage({ body: JSON.stringify(editImageItem.src) });

      mediaSource = downloadResponse.data.url;
    }

    const updatedImageComponents = imageComponents.map((component) => {
      if (component.id === editImageItem.componentId) {
        return {
          ...component,
          src: mediaSource,
        };
      }
      return component;
    });

    setImageComponents(updatedImageComponents);

    setUploadProgress(100);

    setTimeout(() => {
      setUseLocalMedia(false);
      setIsMediaLoading(false);
      setIsUploading(false);
      setUploadProgress(0);
      setEditImageItem({});
      setOpenEditImageModal(false);
      setSelectedFiles([]);
      setSave(true);
    }, 500);
  };

  const handleVideoUpdate = async () => {
    if (isProcessing) return;

    setIsProcessing(true);

    const updatedPrompt = `Edit slide ${index + 1} with the following instructions - ${chatPrompt}`;
    const payload = {
      prompt: updatedPrompt,
    };

    await updateVideo({
      body: JSON.stringify(payload),
    });

    await fetchContent(true);
  };

  const onDuplicate = () => {
    onDuplicateSlide(index);
  };

  const renderTextComponent = (item: any, itemIndex: number) => (
    <span className="w-full">
      <input
        value={item.text}
        className="w-full bg-transparent p-4 mt-5 rounded-xl !overflow-x-scroll border border-borderColor-light outline-none bg-primary-light dark:border-opacity-0 dark:bg-borderColor-dark"
        onChange={(e) => onSetTextChange(itemIndex, e.target.value)}
      />
    </span>
  );

  const onSetSectionChange = (attribute: string, sectionIndex: number, value: string) => {
    const updatedSections = screenplaySlide.sections.map((section: any, localIndex: number) => {
      if (localIndex === sectionIndex) {
        return {
          ...section,
          [attribute]: value,
        };
      }
      return section;
    });

    const updatedScreenplay = {
      ...screenplaySlide,
      sections: updatedSections,
    };

    onSave(
      {
        ...slide,
        components: [...slide.components],
      },
      updatedScreenplay,
      index,
    );

    if (attribute !== 'voiceover') {
      onSetTextChange(sectionIndex, value);
    }
  };

  return (
    <div
      onClick={() => {
        seekToSlide(index);
      }}
      className={`p-6 slide-item-container ${glow && '!border-[#75b351]'} rounded-xl border border-[#d4d4d4] focus-within:!bg-sectionBackground-light focus:!bg-sectionBackground-light hover:!bg-sectionBackground-light focus-within:dark:!bg-[#464646] focus:dark:!bg-[#464646] hover:dark:!bg-[#464646] focus-within:!border-[#FF953D] focus:!border-[#FF953D] hover:!border-[#FF953D] focus:duration-500 hover:duration-500 focus-within:duration-500 bg-[#f0f0f0] dark:bg-sectionBackground-dark dark:border-borderColor-dark`}
    >
      <div>
        {openEditImageModal && (
          <div className={`${styles.modalOverlay} fixed inset-0 flex items-center justify-center !p-6`} onClick={toggleModal}>
            <div className={`bg-secondary-dark text-secondary-light drop-shadow-2xl border border-[#DADADA] rounded-xl ${styles.modal}`} onClick={(e) => e.stopPropagation()}>
              <h4 className="text-lg mb-4 font-medium">Media Upload</h4>
              {!isUploading ? (
                <>
                  <span className="text-sm font-medium mb-2">Choose Files or Enter URL</span>
                  <label
                    htmlFor="file-upload"
                    className={`border-1 border-dashed py-3 border-[#757575] rounded-xl mb-4 bg-[#F2F4F7] text-center text-sm cursor-pointer block w-full ${editImageItem.src ? 'opacity-50 pointer-events-none' : ''}`}
                  >
                    <input
                      onChange={handleMediaUpload}
                      id="file-upload"
                      type="file"
                      className="sr-only"
                      accept="image/*, video/*"
                      disabled={!!editImageItem.src}
                    />
                    {editImageItem.src ? 'URL is entered, cannot upload files.' : 'Click here to Upload'}
                  </label>
                  <div className="mb-4">
                    <input
                      type="text"
                      placeholder="Enter image or video URL"
                      value={editImageItem.src || ''}
                      onChange={(e) => {
                        setEditImageItem((prev: any) => ({
                          ...prev,
                          src: e.target.value,
                        }));
                        setSelectedFiles([]);
                      }}
                      className="w-full px-4 py-2 rounded-md bg-sectionBackground-light text-sm placeholder:text-gray-700 outline-none"
                    />
                  </div>
                  {selectedFiles.length > 0 && (
                    <div className="flex gap-4 minimal-scrollbar-horizontal overflow-x-auto">
                      {selectedFiles.map((file, index) => (
                        <div key={index} className="relative flex-shrink-0">
                          {file.type.startsWith('video') ? (
                            <video
                              src={URL.createObjectURL(file)}
                              className="size-12 object-cover rounded-md"
                              autoPlay
                              muted
                              loop
                            />
                          ) : (
                            <img
                              src={URL.createObjectURL(file)}
                              alt={`preview-${index}`}
                              className="size-12 object-cover rounded-md"
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="w-full flex justify-center font-medium items-center gap-2">
                    <Button
                      size="md"
                      className="!bg-transparent !text-primary-dark"
                      onClick={toggleModal}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="md"
                      className="dark:!text-secondary-dark text-secondary-dark dark:!bg-primary-dark bg-tertiary-light rounded-xl"
                      onClick={onSaveEditImage}
                      isLoading={isMediaLoading}
                      isDisabled={selectedFiles.length === 0 && !editImageItem.src}
                    >
                      Update
                    </Button>
                  </div>
                </>
              ) : (
                <div className="w-full text-start pb-1">
                  <p className="dark:text-primary-dark text-primary-light font-medium">Uploading Progress</p>
                  <div className="w-full bg-gray-200 rounded-full overflow-hidden h-4 mt-3">
                    <div
                      className="bg-tertiary-light dark:bg-black h-full rounded-full"
                      style={{ width: `${uploadProgress}%` }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {showSlideEditingModal && (
          <div className={`${styles.modalOverlay} fixed inset-0 flex items-center justify-center`}>
            <div className={`bg-secondary-dark text-secondary-light drop-shadow-2xl border border-[#DADADA] ${styles.modal}`} onClick={(e) => e.stopPropagation()}>
              <textarea
                placeholder="Describe the changes you would like to make"
                className="w-full px-4 py-2 resize-none rounded-md bg-sectionBackground-light text-sm placeholder:text-gray-700 outline-none"
                value={chatPrompt}
                onChange={(e) => setChatPrompt(e.target.value)}
              />
              <div className="w-full flex justify-end items-center gap-2">
                <Button
                  size="md"
                  className="!bg-transparent !text-primary-dark"
                  variant="light"
                  onClick={() => { closeChatModal(); }}
                >
                  Cancel
                </Button>
                <Button
                  size="md"
                  className="dark:!text-secondary-dark text-secondary-dark dark:!bg-primary-dark bg-tertiary-light rounded-xl"
                  onClick={handleVideoUpdate}
                  isLoading={isProcessing}
                >
                  Update
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-between">
        <div className="font-semibold text-xl">
          {index === 0 ? 'Introduction' : index === 1 ? 'Video Sections' : 'Conclusion'}
        </div>
        {showChatEditOption && (
          <div className="flex gap-2">
            <Button
              onClick={() => openChatModal()}
              size="sm"
              color="default"
              className="dark:text-primary-dark bg-tertiary-light dark:bg-tertiary-dark text-primary-light rounded-md"
              isIconOnly
            >
              <img className="invert dark:invert-0" src=" /icons/chat.svg" style={{ fontSize: '1rem' }} />
            </Button>
            <Button
              onClick={() => {
                onDelete(index);
                setGlow(false);
              }}
              size="sm"
              color="default"
              className="dark:text-primary-dark  text-primary-light bg-danger rounded-md"
              isIconOnly
            >
              <img className="invert dark:invert-0" src="/icons/trash.svg" style={{ fontSize: '1rem' }} />
            </Button>
            <Button
              onClick={onDuplicate}
              size="sm"
              color="default"
              className="bg-tertiary-light dark:bg-tertiary-dark rounded-md"
              isIconOnly
            >
              <img className="invert dark:invert-0" src="/icons/duplicate.svg" style={{ fontSize: '1rem' }} />
            </Button>
          </div>
        )}
      </div>
      <div>
        <div className="flex gap-2 overflow-hidden">
          {editableImageComponents.map((image: any) => {
            if (image.editable === false) return null;
            return (
              <div
                onClick={() => onOpenEditImage(image.id, image.src, COMPONENT_TYPE.Image)}
                className={`${styles.slideItemImage} relative cursor-pointer`}
                key={`${image.src}-${image.id}`}
              >
                {image.src?.match(/\.(mp4|mov|webm|ogg)$/i) ? (
                  <video>
                    <source
                      src={`${image.src}#t=0.1`}
                      type={`video/${image.src?.split('.').pop()}`}
                    />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img src={image.src} alt="Media content" />
                )}
                <div className="w-full h-full top-0 rounded-[10px] absolute bg-black bg-opacity-40 z-20" />
                <div className={styles.editImageButton}>
                  <Button
                    onClick={() => onOpenEditImage(image.id, image.src, COMPONENT_TYPE.Image)}
                    color="primary"
                    className="text-primary-dark  bg-secondary-dark !min-w-0 w-5 h-5 rounded-md"
                    isIconOnly
                  >
                    <img className="size-[10px]" src="/icons/edit-icon-2.svg" />
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="w-full text-wrap break-words overflow-hidden">
        {screenplaySlide.sections ? (
          <>
            {screenplaySlide.sections.map((section: any, sectionIndex: number) => (
              <div className="w-full mt-6" key={sectionIndex}>
                <div className="mb-1 font-medium">Title</div>
                <input
                  value={section.title}
                  className="w-full bg-transparent p-4 rounded-xl !overflow-x-scroll border border-borderColor-light outline-none bg-primary-light dark:border-opacity-0 dark:bg-borderColor-dark"
                  onChange={(e) => onSetSectionChange('title', sectionIndex, e.target.value)}
                />
                <Spacer y={2} />
                {'voiceover' in section && (
                  <>
                    <div className="mb-1 font-medium">Voiceover</div>
                    <textarea
                      className="resize-none w-full h-[100px] rounded-lg p-4 no-scrollbar border dark:border-borderColor-dark border-borderColor-light outline-none bg-primary-light dark:bg-borderColor-dark"
                      value={section.voiceover}
                      onChange={(e) => onSetSectionChange('voiceover', sectionIndex, e.target.value)}
                    />
                  </>
                )}
              </div>
            ))}
          </>
        ) : textComponents && textComponents.map((item: any, itemIndex: number) => (
          <div className="w-full" key={item.id}>
            {renderTextComponent(item, itemIndex)}
          </div>
        ))}
        {!screenplaySlide.sections && (
          <div className="mt-5 items-start gap-2">
            <div className="mb-1 font-medium">Voiceover</div>
            <textarea
              className="resize-none w-full h-[100px] rounded-lg p-4 no-scrollbar border dark:border-borderColor-dark border-borderColor-light outline-none bg-primary-light dark:bg-borderColor-dark"
              value={voiceover}
              onChange={(e: any) => onSetVoiceover(e.target.value)}
            />
          </div>
        )}
      </div>
      <Snackbar
        open={isSnackbarVisible}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        autoHideDuration={1500}
        onClose={() => {
          setIsSnackbarVisible(false);
        }}
      >
        <Alert severity="info">Click Save to update the voiceover</Alert>
      </Snackbar>
    </div>
  );
}
