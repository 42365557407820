import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button, Select, SelectItem, Spacer, Spinner,
} from '@nextui-org/react';

import { Icon } from '@iconify/react';
import AuthContext from '@/auth/context';
import useFetch from '@/hooks/useFetch';
import LicaPage from '@/components/LicaPage';

import styles from './index.module.css';

function ProductVideo() {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [prompt, setPrompt] = useState('');
  const [useGemini, setUseGemini] = useState<boolean>(false);
  const [templateId, setTemplateId] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // State for a single file
  const [
    videoPreview,
    setVideoPreview,
  ] = useState<string | null>(null); // State for a single image preview
  const [isGenerating, setIsGenerating] = useState(false);
  const [contentOptions, setContentOptions] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);

  const {
    data: getData,
    triggerFetch: triggerGetFetch,
  } = useFetch<any>(`${import.meta.env.VITE_BACKEND_URL}/api/v1/content-options/`, { method: 'GET' });

  useEffect(() => {
    triggerGetFetch();
  }, []);

  useEffect(() => {
    if (!getData) return;
    setContentOptions(getData.data);
    setIsLoading(false);
  }, [getData]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null; // Only take the first file
    if (file && file.type.startsWith('video/')) {
      setSelectedFile(file);
      setVideoPreview(URL.createObjectURL(file));
    }
  };

  const handleRemoveImage = () => {
    setSelectedFile(null);
    setVideoPreview(null);
  };

  const getSignedUrl = async (file: any) => {
    const token = await authContext.currentUser?.getIdToken();
    const response = await fetch(`${import.meta.env.VITE_BACKEND_URL}/api/v1/gcs-signed-url/`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        fileName: file.name,
      }),
    }).then((res) => res.json());

    return response.data.signed_url;
  };

  const handleSubmit = async () => {
    if (!selectedFile) return;

    setIsGenerating(true);

    const signedUrl = await getSignedUrl(selectedFile);
    const fileUploadResponse = await fetch(signedUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      body: selectedFile,
    });
    if (!fileUploadResponse.ok) {
      setIsGenerating(false);
      return;
    }

    const data: any = {
      prompt,
      content_types: [{
        type: 'VIDEO',
        params: {
          pipeline: 'PRODUCT_VIDEO',
          video_url: signedUrl.split(['?'])[0],
          use_gemini: useGemini,
          template_id: templateId,
        },
      }],
    };

    try {
      const formData = new FormData();
      formData.append('data', JSON.stringify(data));

      const token = await authContext.currentUser?.getIdToken();

      const postResponse = await fetch(`${import.meta.env.VITE_BACKEND_URL}/api/v1/pages/`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }).then((res) => res.json());

      if (postResponse) {
        navigate(`/pages/${postResponse.data.page_id}/`);
      }
    } finally {
      setIsGenerating(false);
    }
  };

  async function checkIfExtensionInstalled(extensionId: string) {
    try {
      await fetch(`chrome-extension://${extensionId}/lica.png`);
      return true;
    } catch (e) {
      return false;
    }
  }

  const onRecordClick = () => {
    const EXTENSION_ID = 'incigpegfmahknclkdglneelfopdgagg';
    checkIfExtensionInstalled(EXTENSION_ID).then((isInstalled) => {
      if (isInstalled) {
        // handle failure
        window.chrome.runtime?.sendMessage(
          EXTENSION_ID,
          { action: 'start-screen-record' },
          (response: any) => {
            console.log(response);
          },
        );
      } else {
        // open extension store
        window.open('https://chromewebstore.google.com/detail/lica-screen-recorder/incigpegfmahknclkdglneelfopdgagg', '_blank')?.focus();
      }
    });
  };

  return (
    <LicaPage>
      {isLoading ? (
        <div
          className="flex justify-center h-[calc(100vh-150px)] flex-col gap-5 dark:text-secondary-dark text-secondary-light items-center"
          style={{ minHeight: 'inherit' }}
        >
          <Spinner color="current" size="lg" />
        </div>
      ) : (
        <div
          className="bg-sectionBackground-light dark:bg-transparent sm:px-5 py-6 px-10 rounded-3xl flex flex-col items-center justify-center m-auto max-w-[1280px]"
        >
          <Spacer y={3} />
          <div className={`${styles.yourStoryContainer}`}>
            <label className="pl-1 xsm:text-base text-lg font-medium">
              Upload the raw screen recording with voiceover
            </label>
            <div
              className="min-h-36 flex gap-2 items-center justify-center bg-primary-light dark:!bg-[#1C1C1C] border dark:border-borderColor-dark border-borderColor-light p-4 outline-none rounded-2xl mt-2"
              onDragOver={(e) => e.preventDefault()}
              onDrop={(e) => {
                e.preventDefault();
                if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                  const file = e.dataTransfer.files[0];
                  if (file.type.startsWith('video/')) {
                    setSelectedFile(file);
                    setVideoPreview(URL.createObjectURL(file));
                  }
                }
              }}
            >
              <Icon
                icon="solar:videocamera-add-linear"
                className="text-secondary opacity-70"
                style={{ fontSize: '40px' }}
              />
              <div className="flex gap-1.5 color-[#f3f3f3] font-bold opacity-70">
                <div
                  className="underline cursor-pointer"
                  onClick={() => fileInputRef.current?.click()}
                >
                  Upload video
                </div>
                <div>or</div>
                <div
                  className="underline cursor-pointer"
                  onClick={onRecordClick}
                >
                  Record now
                </div>
              </div>
            </div>
            <div>
              <input
                type="file"
                id="file-upload"
                className={styles.imageUploadInput}
                onChange={handleFileChange}
                ref={fileInputRef}
                accept=".mp4,.mov,.webm"
              />
            </div>
            {videoPreview && (
              <div className={styles.imagePreviewContainer}>
                <div className="relative">
                  <video
                    src={videoPreview}
                    className={styles.imagePreview}
                  />
                  <button
                    type="button"
                    onClick={handleRemoveImage}
                    className={styles.removeButton}
                  >
                    <Icon icon="carbon:close" style={{ fontSize: '1rem', color: 'black' }} />
                  </button>
                </div>
              </div>
            )}
          </div>
          <Spacer y={6} />
          <div className="w-full">
            <label className="pl-1 xsm:text-base text-lg font-medium">
              Prompt
            </label>
            <textarea
              value={prompt}
              className="resize-none rounded-[10px] w-full sm:text-sm sm:h-20 text-lg outline-none minimal-scrollbar mt-2 bg-primary-light sm:placeholder:text-sm dark:!bg-[#1C1C1C] border dark:border-borderColor-dark border-borderColor-light px-4 py-2 dark:text-secondary-dark text-secondary-light placeholder:text-[#475467] dark:placeholder:text-[#737373]"
              placeholder="Specify instructions about the audience this product demo is for, style and tone"
              onChange={(e) => setPrompt(e.target.value)}
            />
          </div>
          {authContext.currentUser?.email?.includes('@lica.world') && (
            <div className="flex items-center w-full mt-6">
              <input
                type="checkbox"
                id="gemini-checkbox"
                className={`w-5 h-5 cursor-pointer appearance-none rounded-[4px] focus:ring-secondary ${useGemini
                  ? 'border-gray-300 dark:checked:bg-[#93C5FD] checked:bg-[#6E400A] invert-[1] dark:invert-0 checked:bg-center checked:bg-no-repeat checked:bg-[length:60%_50%]'
                  : 'border dark:border-[#575757] border-[#d3d3d3] bg-primary-light dark:!bg-[#1C1C1C]'
                  }`}
                style={{
                  backgroundImage: useGemini
                    ? 'url(/icons/tick.svg)'
                    : 'none',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '60% 50%',
                }}
                checked={useGemini}
                onChange={(e) => setUseGemini(e.target.checked)}
              />
              <label
                htmlFor="gemini-checkbox"
                className="ml-2 dark:text-secondary-dark text-secondary-light cursor-pointer"
              >
                Use Gemini
              </label>
            </div>
          )}
          {authContext.currentUser?.email?.includes('@lica.world') && contentOptions.VIDEO.templates_product_video?.length > 0 && (
            <div className="w-full mt-6">
              <Select
                classNames={{
                  label: ['dark:text-secondary-dark text-secondary-dark !text-opacity-80'],
                  selectorIcon: ['dark:text-secondary-dark text-primary-dark'],
                  value: ['dark:text-secondary-dark text-primary-dark'],
                }}
                labelPlacement="outside"
                label="Choose Product Video Template ID"
                placeholder="Select"
                selectedKeys={new Set(templateId ? [templateId] : [])}
                onChange={(e) => setTemplateId(e.target.value)}
              >
                {contentOptions.VIDEO.templates_product_video?.map((template: string) => (
                  <SelectItem
                    classNames={{
                      title: ['dark:text-secondary-dark text-primary-dark'],
                    }}
                    key={template}
                    value={template}
                  >
                    {template}
                  </SelectItem>
                ))}
              </Select>
            </div>
          )}
          {contentOptions?.ALLOW_GENERATE
            ? (
              <Button
                color="primary"
                className="px-4 py-6 mt-10 rounded-xl text-base font-medium text-primary-light dark:text-black hover:dark:bg-secondary-dark bg-tertiary-light dark:bg-secondary-dark"
                onClick={handleSubmit}
                isDisabled={!selectedFile}
                isLoading={isGenerating}
              >
                {isGenerating ? 'Generating Video' : 'Generate Video'}
              </Button>
            )
            : (
              <div className="max-w-md mx-auto mt-8">
                <div className="rounded-lx">
                  <div className="flex items-start">
                    <div className="flex text-center flex-col items-center">
                      <svg
                        className="h-6 w-6 text-error-light dark:text-error-dark"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 8v4m0 4h.01m6.938-4A7.938 7.938 0 0112 19.938 7.938 7.938 0 015.062 12 7.938 7.938 0 0112 4.062 7.938 7.938 0 0118.938 12z"
                        />
                      </svg>
                      <p className="mt-1 text-sm text-error-light dark:text-error-dark font-semibold">
                        You have reached the maximum free generated videos.
                      </p>
                      <p className="mt-1 text-sm text-gray-800 dark:text-gray-300">
                        Please upgrade your membership to generate more videos.
                      </p>
                      <button
                        type="button"
                        onClick={() => window.open('https://www.lica.world/pricing', '_blank')}
                        className="mt-4 text-primary-light dark:text-black dark:hover:bg-opacity-85 bg-tertiary-light dark:bg-secondary-dark px-4 py-2 font-medium rounded-lx hover:bg-opacity-85 "
                      >
                        Upgrade Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      )}
    </LicaPage>
  );
}

export default ProductVideo;
