import React, {
  RefObject, useEffect, useRef, useState,
} from 'react';
import { PlayerRef } from '@remotion/player';
import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';

import { isArray } from 'lodash';
import { AnimationFactory } from '../animation/animation';
import { useGsapTimeline } from '../animation/gsap';
import Card from './card';
import Text from './text';
import Image from './image';
import SVG from './svg';
import Video from './video';
import PaginatedSubtitles from './subtitle';
import AudioComp from './audio';
import MinimalListicle from './minimal-listicle';
import Circular from './circlular';
import Carousel from './carousel/carousel';
import WipeCarousel from './carousel/wipe-carousel';
import TagsListicle from './tags-listicle';
import TitlePopup from './title-popup';
import ImageMinimalListicle from './image-minimal-listicle';
import TagsBullets from './tags-bullets';
import GradientList from './gradient-list';

gsap.registerPlugin(SplitText);

interface Props {
  component: {
    animation?: {
      type: string;
      attributes?: {
        direction: string;
        divider: string;
        duration: number;
        stagger: number;
        ease: string;
        opacity: number;
        animate: string;
        delay: number;
        letterSpacing: number;
        disappearingFactor: number;
      };
    };
    [key: string]: any;
  };
  player?: RefObject<PlayerRef>;
  slideDuration?: number;
  delay?: number;
}

function Animated({ component, player, slideDuration }: Props) {
  const container = useRef<any>(null);
  const [animatedTimelines, setAnimatedTimelines] = useState({
    appearingTimeline: 0,
    disappearingTimeline: 0,
  });

  useGsapTimeline<HTMLDivElement>(() => {
    let animatedElements;

    if ((component.type === 'TEXT' || component.type === 'CIRCULAR') && component.animation) {
      type DividerType = 'lines' | 'words' | 'chars';
      const dividerType: DividerType = component.animation?.attributes?.divider as DividerType || 'lines';

      const splitType = dividerType === 'chars' ? 'words,chars' : dividerType;

      const split = new SplitText(container.current, { type: splitType });

      const shouldWrap = component.animation.type === 'baseline';

      if (shouldWrap) {
        const wrapElements = (elements: Element[]) => {
          elements.forEach((element) => {
            if (element instanceof HTMLElement) {
              const wrapper = document.createElement('div');
              wrapper.style.overflow = 'hidden';
              wrapper.style.position = 'relative';

              element.parentElement?.insertBefore(wrapper, element);
              wrapper.appendChild(element);
            }
          });
        };

        if (dividerType === 'chars') {
          wrapElements(split.chars as Element[]);
          animatedElements = split.chars as HTMLElement[];
        } else if (dividerType === 'words') {
          wrapElements(split.words as Element[]);
          animatedElements = split.words as HTMLElement[];
        } else if (dividerType === 'lines') {
          wrapElements(split.lines as Element[]);
          animatedElements = split.lines as HTMLElement[];
        }
      } else if (dividerType === 'chars') {
        animatedElements = split.chars || split.words || container.current;
      } else {
        animatedElements = split[dividerType] || container.current;
      }
    } else {
      animatedElements = container.current;
    }

    const animationFactory = AnimationFactory.createAnimation(
      component.animation?.type as string,
    );

    const {
      gsapTimeline,
      appearingTimeline,
      disappearingTimeline,
    } = animationFactory.createTimeline(
      animatedElements,
      component.animation?.attributes,
      slideDuration as number,
    );
    setAnimatedTimelines({ appearingTimeline, disappearingTimeline });

    return gsapTimeline;
  });

  useEffect(() => {
    // console.log(animatedTimelines);
  }, [animatedTimelines]);

  const onClick = (link: any) => {
    if (link) {
      player?.current?.pause();
      window.open(link, '_blank');
    }
  };

  const renderGroupComponents = (comp: any) => (
    <div
      ref={container}
      style={{
        ...comp.style,
        cursor: comp.link ? 'pointer' : 'default',
      }}
      onClick={() => onClick(comp.link)}
    >
      {comp.components?.map((compon: any) => (
        <Animated
          key={compon.id}
          component={compon}
          player={player}
          slideDuration={slideDuration}
        />
      ))}
    </div>
  );

  const [reCalculated, setReCalculated] = useState(false);

  useEffect(() => {
    if (component.type === 'TEXT' && !reCalculated && component.componentStyle === 'TITLE_POPUP') {
      if (!container.current) return;

      const containerWidth = container.current.clientWidth;
      const paddingLeft = parseFloat(window.getComputedStyle(container.current).paddingLeft || '0');
      const paddingRight = parseFloat(window.getComputedStyle(container.current).paddingRight || '0');
      const availableWidth = containerWidth - paddingLeft - paddingRight;
      const values = component.itemStyles.padding ? component.itemStyles.padding.split(' ') : '0';

      let currentLine = '';
      const tempLines: string[] = [];

      if (component.animation?.attributes?.divider === 'words') {
        const lines = component.text.split(' ').length;
        const paddingY = parseFloat(component.itemStyles?.padding?.toString() || '0');
        const itemHeight = container.current.clientHeight / lines;

        let fontSize = (itemHeight - paddingY) / 2;
        const longestWord = component.text.split(' ').reduce((longest: any, current: any) => (current.length > longest.length ? current : longest), '');

        if (container.current.clientWidth < fontSize * (longestWord.length - 1)) {
          fontSize = container.current.clientWidth / (longestWord.length - 1);
          if (longestWord.length < 10) {
            component.itemStyles.height = `${fontSize * 2}px`;
          }
        } else {
          component.itemStyles.height = `${itemHeight}px`;
        }
        component.itemStyles.fontSize = `${fontSize}px`;
      } else if (!isArray(component.text)) {
        const words = component.text.split(' ');
        words.forEach((word: any, index: any) => {
          const testLine = currentLine + (currentLine ? ' ' : '') + word;
          const span = document.createElement('span');
          span.innerText = testLine;
          span.style.fontSize = `${parseFloat(component.itemStyles.fontSize || '16')}px`;
          span.style.position = 'absolute';
          span.style.visibility = 'hidden';
          span.style.fontFamily = 'Haffer';
          span.style.padding = `0 ${parseFloat(values[values.length - 1])}px`;

          document.body.appendChild(span);
          const testWidth = span.offsetWidth;
          document.body.removeChild(span);

          if (testWidth > availableWidth && currentLine) {
            tempLines.push(currentLine);
            currentLine = word;
          } else {
            currentLine = testLine;
          }
          if (index === words.length - 1) {
            tempLines.push(currentLine);
          }
        });
        const fontSize = parseFloat(component.itemStyles.fontSize || '16');
        const currentItemHeights = (fontSize * 1.5) * tempLines.length;
        const getLongestString = (arr: any) => arr.reduce((longest: any, current: any) => (current.length > longest.length ? current : longest), '');

        let optimalHeightOfItems;
        let optimalFontSizeOfItems = fontSize;
        const longestString = getLongestString(tempLines);
        if (container.current.clientHeight < currentItemHeights) {
          optimalHeightOfItems = container.current.clientHeight / tempLines.length;
          optimalFontSizeOfItems = optimalHeightOfItems / 1.5;
          component.itemStyles.fontSize = `${optimalFontSizeOfItems}px`;
          component.itemStyles.height = `${optimalHeightOfItems}px`;
        }

        const span = document.createElement('span');
        span.style.fontSize = `${optimalFontSizeOfItems}px`;
        span.style.fontFamily = component.itemStyles.fontFamily ? component.itemStyles.fontFamily : 'Haffer';
        span.style.position = 'absolute';
        span.style.visibility = 'hidden';
        span.style.whiteSpace = 'nowrap';
        span.style.padding = `0 ${parseFloat(values[values.length - 1])}px`;
        span.innerText = longestString;

        document.body.appendChild(span);
        const longestWidth = span.offsetWidth + 1;
        document.body.removeChild(span);

        const findOptimalFontSize = (component: any, longestString: any, container: any) => {
          let optimalFontSize = parseFloat(component.itemStyles.fontSize || '16');
          const fontFamily = component.itemStyles.fontFamily ? component.itemStyles.fontFamily : 'Haffer';

          const span = document.createElement('span');
          span.style.position = 'absolute';
          span.style.visibility = 'hidden';
          span.style.whiteSpace = 'nowrap';
          span.style.padding = `0 ${parseFloat(values[values.length - 1])}px`;
          span.innerText = longestString;
          document.body.appendChild(span);

          while (true) {
            span.style.fontSize = `${optimalFontSize}px`;
            span.style.fontFamily = fontFamily;
            const longestWidth = span.offsetWidth;

            if (container.current.clientWidth >= longestWidth) {
              break;
            }

            optimalFontSize -= 1;
          }

          document.body.removeChild(span);

          return optimalFontSize;
        };

        if (container.current.clientWidth < longestWidth) {
          const optimalFontSize = findOptimalFontSize(component, longestString, container);
          component.itemStyles.fontSize = `${optimalFontSize}px`;
        }

        const recalculatedLines: string[] = [];
        let recallCurrentLine = '';

        words.forEach((word: any, index: any) => {
          const testLine = recallCurrentLine + (recallCurrentLine ? ' ' : '') + word;
          const span = document.createElement('span');
          span.innerText = testLine;
          span.style.fontSize = `${parseFloat(component.itemStyles.fontSize || '16')}px`;
          span.style.position = 'absolute';
          span.style.visibility = 'hidden';
          span.style.fontFamily = 'Haffer';
          span.style.padding = `0 ${parseFloat(values[values.length - 1])}px`;

          document.body.appendChild(span);
          const testWidth = span.offsetWidth;
          document.body.removeChild(span);

          if (testWidth > availableWidth && recallCurrentLine) {
            recalculatedLines.push(recallCurrentLine);
            recallCurrentLine = word;
          } else {
            recallCurrentLine = testLine;
          }
          if (index === words.length - 1) {
            recalculatedLines.push(currentLine);
          }
        });
        component.text = recalculatedLines;
      }
      setReCalculated(true);
    }
  }, [component]);

  const renderComponent = (comp: any) => (comp.type === 'TEXT' ? (
    comp.componentStyle === 'TITLE_POPUP' ? (
      <TitlePopup
        ref={container}
        text={reCalculated && comp.text}
        style={comp.style}
        animation={comp.animation}
        itemStyles={reCalculated && comp.itemStyles}
        slideDuration={slideDuration as number}
      />
    ) : (
      <Text
        ref={container}
        text={comp.text}
        autoResize
        style={{
          ...comp.style,
          cursor: comp.link ? 'pointer' : 'default',
        }}
        onClick={() => onClick(comp.link)}
      />
    )
  ) : comp.type === 'TAGS_LISTICLE' ? (
    <TagsListicle
      ref={container}
      data={component.data}
      style={comp.style}
      slideDuration={slideDuration as number}
    />
  ) : comp.type === 'TAGS_BULLETS' ? (
    <TagsBullets
      ref={container}
      data={component.data}
      style={comp.style}
      slideDuration={slideDuration as number}
    />
  ) : comp.type === 'CIRCULAR' ? (
    <Circular
      ref={container}
      text={comp.text}
      style={comp.style}
      fontSize={comp.fontSize}
    />
  ) : comp.componentStyle === 'GRADIENT_LIST' ? (
    <GradientList
      // ref={container}
      items={comp.items}
      style={comp.style}
      itemStyle={comp.itemStyle}
      animation={comp.animation}
    />
  ) : ['IMAGE', 'VIDEO', 'SVG'].includes(comp.type) ? (
    comp.src?.match(/\.(mp4|mov|webm|ogg)$/i) ? (
      <Video
        ref={container}
        src={comp.src}
        style={comp.style}
        volume={comp.volume || 0}
        startFrom={comp.startFrom}
        loop={comp.loop !== false}
      />
    ) : comp.src?.match(/\.(svg)$/i) ? (
      <SVG
        ref={container}
        src={comp.src}
        style={{
          ...comp.style,
          cursor: comp.link ? 'pointer' : 'default',
        }}
        onClick={() => onClick(comp.link)}
      />
    ) : (
      <Image
        ref={container}
        src={comp.src}
        style={{
          ...comp.style,
          cursor: comp.link ? 'pointer' : 'default',
        }}
        onClick={() => onClick(comp.link)}
        slideDuration={slideDuration as number}
        enableBreath={!component.animation}
      />
    )
  ) : comp.type === 'CARD' ? (
    <Card
      ref={container}
      title={component.title}
      subtitle={component.subtitle}
      image={component.image}
      style={comp.style}
      slideDuration={slideDuration as number}
    />
  ) : comp.type === 'MINIMAL_LISTICLE' ? (
    <MinimalListicle
      ref={container}
      data={component.data}
      style={comp.style}
      slideDuration={slideDuration as number}
    />
  ) : comp.type === 'IMAGE_MINIMAL_LISTICLE' ? (
    <ImageMinimalListicle
      ref={container}
      data={component.data}
      style={comp.style}
      slideDuration={slideDuration as number}
    />
  ) : comp.type === 'AUDIO' ? (
    <AudioComp
      audioSrc={comp.src}
      style={comp.style}
      waveColor={comp.waveColor}
      mirrorWave={comp.mirrorWave}
      freqRangeStartIndex={comp.waveFreqRangeStartIndex}
      numberOfSamples={comp.waveNumberOfSamples}
      waveLinesToDisplay={comp.waveLinesToDisplay}
    />
  ) : comp.type === 'CAROUSEL' ? (
    <Carousel ref={container} component={component} />
  ) : comp.type === 'WIPE_CAROUSEL' ? (
    <WipeCarousel ref={container} component={component} />
  ) : comp.type === 'GROUP' ? (
    renderGroupComponents(comp)
  ) : comp.type === 'SOUND_WAVE' ? (
    <AudioComp
      audioSrc={comp.src}
      style={comp.style}
      innerStyle={comp.innerStyle}
      waveColor={comp.waveColor}
      freqRangeStartIndex={4}
      waveLinesToDisplay={comp.waveLines || 29}
      outerStyle={comp.outerStyle}
      frequencyHeight={comp.frequencyHeight}
      numberOfSamples={256}
      mirrorWave
    />
  ) : comp.type === 'SUBTITLES' ? (
    <PaginatedSubtitles
      subtitlesFileName={comp.src}
      linesPerPage={4}
      wrapperStyle={comp.customStyle?.wrapperStyle}
      subtitlesTextColor={comp.customStyle?.subtitleColor}
      subtitlesBackgroundColor={comp.customStyle?.backgroundColor}
      subtitlesActiveTextColor={comp.customStyle?.activeSubtitleColor}
      subtitlesZoomMeasurerSize={1}
      subtitlesLineHeight={25}
      audioOffsetInSeconds={0}
      style={comp.style}
    />
  ) : null);

  return renderComponent(component);
}

export default Animated;
