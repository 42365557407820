import React, {
  forwardRef, useEffect, useRef, useState, useImperativeHandle,
} from 'react';
import { measureText } from '@remotion/layout-utils';

const FONT_SIZE = 16;
// const DEFAULT_FONT_FAMILY = "Haffer";
const MIN_FONT_SIZE = 8; // Define a minimum font size

const Text = forwardRef((props: any, ref) => {
  const innerRef = useRef<HTMLDivElement>(null);
  const autoResize = props.autoResize || false;
  const [intendedFontSize, setIntendedFontSize] = useState<number>(parseInt(props.style?.fontSize, 10) || FONT_SIZE); // Starting font size
  const [contentWidth, setContentWidth] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);

  useImperativeHandle(ref, () => innerRef.current);

  useEffect(() => {
    if (innerRef.current && !props.style?.width) {
      innerRef.current.style.width = '100%';
    }
  }, []);

  useEffect(() => {
    if (!innerRef.current) return;

    const computedStyle = window.getComputedStyle(innerRef.current);
    const verticalPadding = parseFloat(computedStyle.paddingBottom) + parseFloat(computedStyle.paddingTop);

    // Calculating how many lines exist in ref and combine with line height
    let lines = 0;
    let lastTopOffset = -1;
    let childHeightSum = 0;
    const { children } = innerRef.current;

    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      const rect = child.getBoundingClientRect();
      if (rect.top !== lastTopOffset) {
        lastTopOffset = rect.top;
        lines++;
      }
    }
    if (children[0]) {
      childHeightSum = children[0].clientHeight * lines;
    }

    if (children.length === 0) {
      if (autoResize && intendedFontSize > MIN_FONT_SIZE && innerRef.current.scrollHeight > innerRef.current.clientHeight) {
        setIntendedFontSize((prevFontSize) => prevFontSize - 1);
      }
    }
    setContentHeight(childHeightSum + verticalPadding);
  }, [intendedFontSize, contentHeight, contentWidth, props.text]);

  useEffect(() => {
    if (!innerRef.current) return;
    const computedStyle = window.getComputedStyle(innerRef.current);
    const horizontalPadding = parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
    const width = innerRef.current.clientWidth;
    setContentWidth(width - horizontalPadding);
  }, []);

  // Checks if any word in the text overflows the current width
  const checkIfWidthOverflowing = (fontSize: number) => props.text.split(' ').some((word: string) => {
    const estimate = measureText({
      text: word,
      fontFamily: props.style?.fontFamily,
      fontSize,
      fontWeight: props.style?.fontWeight,
    });

    const wordWidth = estimate.width + word.length;

    return wordWidth > contentWidth;
  });

  // Check if increasing the font size by 1 will cause overflow in order to prevent infinite loop
  const checkIfNextSizeWillOverflow = (fontSize: number) => {
    if (!innerRef.current) return true;

    const nextFontSize = fontSize + 1;

    const willOverflowWidth = checkIfWidthOverflowing(nextFontSize);

    const dummyDiv = document.createElement('div');
    dummyDiv.style.position = 'absolute';
    dummyDiv.style.visibility = 'hidden';
    dummyDiv.style.fontSize = `${nextFontSize}px`;
    dummyDiv.style.lineHeight = 'normal';
    dummyDiv.style.whiteSpace = 'pre-wrap';

    dummyDiv.style.fontFamily = props.style?.fontFamily || 'inherit';
    dummyDiv.style.fontWeight = props.style?.fontWeight || 'normal';
    dummyDiv.style.width = `${innerRef.current.clientWidth}px`;
    dummyDiv.innerText = props.text;

    document.body.appendChild(dummyDiv);

    const dummyHeight = dummyDiv.clientHeight;
    document.body.removeChild(dummyDiv);

    const willOverflowHeight = dummyHeight > innerRef.current.clientHeight;

    return willOverflowWidth || willOverflowHeight;
  };

  // Check and adjust font size if there's an overflow
  const checkOverflow = () => {
    if (!innerRef.current || !props.style?.height || contentWidth === 0) return;

    const isWidthOverflowing = checkIfWidthOverflowing(intendedFontSize);
    const isHeightOverflowing = contentHeight > innerRef.current.clientHeight;

    if (autoResize && (isHeightOverflowing || isWidthOverflowing) && intendedFontSize > MIN_FONT_SIZE) {
      setIntendedFontSize((prevFontSize) => prevFontSize - 1);
    }
  };

  useEffect(() => {
    checkOverflow();
  }, [intendedFontSize, contentHeight, contentWidth, props.text]);

  useEffect(() => {
    if (!innerRef.current) return;

    const isWidthOverflowing = checkIfWidthOverflowing(intendedFontSize);
    const isHeightOverflowing = contentHeight > innerRef.current.clientHeight;

    if (!isHeightOverflowing && !isWidthOverflowing && intendedFontSize < parseInt(props.style?.fontSize, 10) && !checkIfNextSizeWillOverflow(intendedFontSize)) {
      setIntendedFontSize((prevFontSize) => prevFontSize + 1);
    }
  }, [props.text]);

  return (
    <div
      ref={innerRef}
      style={{
        lineHeight: 'normal',
        fontWeight: 400,
        ...props.style,
        fontSize: `${intendedFontSize}px`,
      }}
      onClick={props.onClick}
    >
      {props.text}
    </div>
  );
});

Text.displayName = 'Text';

export default Text;
