import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Spinner } from '@nextui-org/react';

import AuthContext from '@/auth/context';
import { downloadVideo } from '@/services/content';
import RemotionPlayer from '@/remotion/RemotionPlayer';
import ShareButton from '@/pages/v3/components/ShareButton';

function Video({
  contentId, videoData, responseData, progress, onRegenerate,
}: {
  contentId: string;
  videoData?: any;
  responseData?: any;
  progress?: number;
  onRegenerate?: (isRegenerating: boolean, data: any) => void;
}) {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const [isDownloading, setIsDownloading] = useState(false);
  const [isRegenerating, setIsRegenerating] = useState(false);

  const handleDownloadClick = async () => {
    if (isDownloading) return;

    setIsDownloading(true);
    await downloadVideo(contentId, responseData.output.download_links?.media);
    setIsDownloading(false);
  };

  const handleEditClick = async () => {
    navigate(`/content/${contentId}/edit`);
  };

  const regenerateHandler = async () => {
    const token = await authContext.currentUser?.getIdToken();

    const postResponse = await fetch(
      `${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/${contentId}/regenerate`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    ).then((res) => res.json());

    if (onRegenerate) {
      onRegenerate(true, postResponse.data);
    }

    setIsRegenerating(true);
  };

  useEffect(() => {
    if (isRegenerating && responseData?.data?.status === 'failed') {
      setIsRegenerating(false);
      if (onRegenerate) {
        onRegenerate(false, '');
      }
    } else if (responseData?.data?.status === 'processing' && responseData?.data?.is_regenerated) {
      setIsRegenerating(true);
    }
  }, [responseData]);

  return (
    <div className="flex flex-col">
      <div className="h-full flex-col-reverse min-lg:flex-row flex justify-between gap-9">
        <div className="w-full min-lg:w-3/12 flex bg-sectionBackground-light dark:bg-sectionBackground-dark rounded-3xl px-8 py-10 flex-col gap-8">
          <div className="text-2xl minimal-scrollbar break-words overflow-y-auto font-medium">
            {responseData?.output?.title}
          </div>
          {(responseData?.input_params?.prompt || responseData?.data?.input_params?.prompt) && (
            <div className="text-lg sm:text-base">
              <h4 className="font-bold text-tertiary-light dark:text-tertiary-dark">Story</h4>
              <p
                className="w-full minimal-scrollbar minimal-scrollbar-horizontal text-wrap break-words max-h-72 overflow-y-auto mt-1 rounded-xl"
              >
                {responseData?.input_params?.prompt || responseData?.data?.input_params?.prompt}
              </p>
            </div>
          )}
          {(responseData?.input_params?.style_prompt || responseData?.data?.input_params?.style_prompt) && (
            <div className="text-lg sm:text-base">
              <label className="font-bold text-tertiary-light dark:text-tertiary-dark">
                Style Instruction
              </label>
              <p
                className="rounded-[10px] minimal-scrollbar-horizontal text-wrap max-h-32 break-words w-full minimal-scrollbar overflow-y-auto mt-1 dark:text-secondary-dark text-secondary-light placeholder:text-[#737373]"
              >
                {responseData?.input_params?.style_prompt || responseData?.data?.input_params?.style_prompt}
              </p>
            </div>
          )}
          {responseData?.input_params?.assets && responseData?.input_params.assets?.length > 0
            && (
            <div className="pb-2 flex flex-col max-w-[100%] overflow-x-auto gap-2 minimal-scrollbar-horizontal">
              <label className="font-bold text-tertiary-light dark:text-tertiary-dark">
                Media
              </label>
              <div className="flex gap-2 mt-1">
                {responseData.input_params.assets.map((asset: any, index: number) => {
                  const isVideo = /\.(mp4|mov|webm|ogg|avi|mkv)$/.test(asset);

                  return isVideo ? (
                    <video
                      draggable={false}
                      key={index}
                      src={asset}
                      className="max-w-40 object-cover max-h-20 rounded-lg"
                    />
                  ) : (
                    <img
                      draggable={false}
                      key={index}
                      src={asset}
                      alt={`Asset ${index}`}
                      className="max-w-40 object-cover max-h-20 rounded-lg"
                    />
                  );
                })}
              </div>
            </div>
            )}
        </div>
        {responseData.output ? (
          <div className="bg-sectionBackground-light grow dark:bg-sectionBackground-dark w-full min-lg:w-8/12 rounded-xl p-6 flex flex-col items-center">
            <div className="flex items-center justify-center gap-4 mb-6">
              <ShareButton url={`${window.origin}/pages/${responseData?.page_id}`} key={1} />
              <Button
                className="dark:text-primary-dark text-primary-light rounded-xl dark:bg-secondary-dark bg-tertiary-light text-sm font-medium z-10 flex items-center justify-center h-10"
                onClick={handleDownloadClick}
                isLoading={isDownloading}
              >
                {!isDownloading
                  && <img className="invert dark:invert-0" src="/icons/download-icon.svg" alt="Download" />}
                <span className="sm:hidden">
                  Download
                </span>
              </Button>
              {responseData.user_info.email === authContext.currentUser?.email ? (
                <Button
                  className="dark:text-primary-dark text-primary-light rounded-xl dark:bg-secondary-dark bg-tertiary-light text-sm font-medium z-10 flex items-center justify-center w-20 h-10"
                  onClick={handleEditClick}
                >
                  <img className="invert dark:invert-0" src="/icons/edit-icon.svg" alt="Edit" />
                  <span className="sm:hidden">
                    Edit
                  </span>
                </Button>
              ) : null}
            </div>
            <div className="flex flex-col flex-auto items-center w-full">
              <div
                style={{
                  aspectRatio: videoData.width / videoData.height,
                }}
                className={`flex-auto max-h-full w-[calc(100vw-80px)] md:w-[calc(100vw-112px)] ${videoData.width / videoData.height > 1 ? 'max-w-full' : 'max-w-[400px]'}`}
              >
                {responseData.output.download_links ? (
                  <div className="w-full h-full flex items-center justify-center relative">
                    <video className="absolute w-full h-full" src={responseData.output.download_links.media} controls />
                  </div>
                ) : (
                  <RemotionPlayer data={videoData} clickToPlay={false} />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div
            className="bg-sectionBackground-light grow dark:bg-sectionBackground-dark w-full h-[688px] min-lg:w-8/12 rounded-xl sm:h-[60vh] min-lg-height:h-full p-6 flex justify-center items-center"
          >
            {!isRegenerating
              ? (
                <button
                  onClick={regenerateHandler}
                  className="text-xl dark:text-secondary-dark text-primary-light px-4 py-1.5 bg-[#ee822a] dark:bg-[#373839] w-fit rounded-lx"
                >
                  Regenerate
                </button>
              )
              : (
                <div className="flex flex-col gap-4">
                  <div className="text-xl">
                    {`${progress}%`}
                    {' '}
                    Complete
                  </div>
                  <div className="flex gap-4 text-xl">
                    <Spinner size="sm" />
                    <p className="text-lg dark:text-secondary-dark text-secondary-light">Regenerating...</p>
                  </div>
                </div>
              )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Video;
