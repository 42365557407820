import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import { Button, Spacer } from '@nextui-org/react';
import { Icon } from '@iconify/react';
import useFetch from '@/hooks/useFetch';
import ShareButton from '@/pages/v3/components/ShareButton';
import { downloadVideo } from '@/services/content';

import EditAudioModal from './edit-audio-modal';
import EditSlideItemProduct from './edit-slide-item-product';

const AudioType = {
  Music: 'MUSIC',
  VoiceOver: 'VOICEOVER',
};

function SlideItemsProduct(
  {
    videoId, internalVideoData, setInternalVideoData, regenerate,
    setIsProcessing, isProcessing, responseData,
    editSlideIndex, setEditSlideIndex, passSeekValue,
  }: {
    videoId: string;
    internalVideoData: any;
    responseData: any;
    setInternalVideoData: (data: any) => void;
    setIsProcessing: (processing: boolean) => void;
    regenerate: (value: boolean) => void;
    isProcessing: boolean;
    passSeekValue: (value: number) => void;
    editSlideIndex: number | null;
    setEditSlideIndex: (index: number | null) => void;
  },
) {
  const [openEditAudioModal, setOpenEditAudioModal] = useState(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>(NaN);

  const showSlideChatEditOption = internalVideoData.type !== 'product_video';

  const navigate = useNavigate();

  const { fetchData: updateVideo } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/${videoId}/update`,
    { method: 'POST' },
  );

  const handleSaveClick = async () => {
    if (isProcessing) {
      return;
    }

    setIsProcessing(true);

    const data = {
      screenplay_slides: internalVideoData.screenplay_slides,
      unhydrated_slides: internalVideoData.unhydrated_slides,
      subtitles_enabled: internalVideoData.subtitles_enabled,
      bg_music: internalVideoData.bg_music,
    };

    try {
      const saveResponse = await updateVideo({
        body: JSON.stringify(data),
      });

      if (['initiated', 'processing'].includes(saveResponse.data.update_status)) {
        regenerate(true);
      } else {
        setIsProcessing(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDuplicateSlide = (index: number) => {
    setActiveIndex(index + 1);
    setTimeout(() => {
      setActiveIndex(NaN);
    }, 3000);

    if (index >= internalVideoData.video_data.slides.length) {
      setInternalVideoData({
        ...internalVideoData,
        video_data: {
          ...internalVideoData.video_data,
          slides: [
            ...internalVideoData.video_data.slides,
            {
              ...internalVideoData.video_data.slides[-1],
            },
          ],
        },
        screenplay_slides: [
          ...internalVideoData.screenplay_slides,
          {
            ...internalVideoData.screenplay_slides[-1],
          },
        ],
        unhydrated_slides: [
          ...internalVideoData.unhydrated_slides,
          {
            ...internalVideoData.unhydrated_slides[-1],
          },
        ],
        presenter_data: {
          ...internalVideoData.presenter_data,
          slide_durations: [
            ...internalVideoData.presenter_data.slide_durations,
            internalVideoData.presenter_data.slide_durations[-1],
          ],
        },
      });
    } else {
      setInternalVideoData({
        ...internalVideoData,
        video_data: {
          ...internalVideoData.video_data,
          slides: [
            ...internalVideoData.video_data.slides.slice(0, index + 1),
            {
              ...internalVideoData.video_data.slides[index],
            },
            ...internalVideoData.video_data.slides.slice(index + 1),
          ],
        },
        screenplay_slides: [
          ...internalVideoData.screenplay_slides.slice(0, index + 1),
          {
            ...internalVideoData.screenplay_slides[index],
          },
          ...internalVideoData.screenplay_slides.slice(index + 1),
        ],
        unhydrated_slides: [
          ...internalVideoData.unhydrated_slides.slice(0, index + 1),
          {
            ...internalVideoData.unhydrated_slides[index],
          },
          ...internalVideoData.unhydrated_slides.slice(index + 1),
        ],
        presenter_data: {
          ...internalVideoData.presenter_data,
          slide_durations: [
            ...internalVideoData.presenter_data.slide_durations.slice(0, index + 1),
            internalVideoData.presenter_data.slide_durations[index],
            ...internalVideoData.presenter_data.slide_durations.slice(index + 1),
          ],
        },
      });
    }
  };

  const handleDeleteSlide = (index: number) => {
    setInternalVideoData({
      ...internalVideoData,
      video_data: {
        ...internalVideoData.video_data,
        slides: [
          ...internalVideoData.video_data.slides.slice(0, index),
          ...internalVideoData.video_data.slides.slice(index + 1),
        ],
      },
      screenplay_slides: [
        ...internalVideoData.screenplay_slides.slice(0, index),
        ...internalVideoData.screenplay_slides.slice(index + 1),
      ],
      unhydrated_slides: [
        ...internalVideoData.unhydrated_slides.slice(0, index),
        ...internalVideoData.unhydrated_slides.slice(index + 1),
      ],
    });
  };

  const handleSaveSlide = (slide: any, updatedScreenplaySlide: any, index: number) => {
    const cloneSlides = [...internalVideoData.video_data.slides];
    const cloneSlide = { ...cloneSlides[index] };
    cloneSlides[index] = {
      ...cloneSlide,
      ...slide,
    };

    setInternalVideoData((currentVal: any) => ({
      ...currentVal,
      video_data: {
        ...currentVal.video_data,
        slides: cloneSlides,
      },
      screenplay_slides: [
        ...currentVal.screenplay_slides.slice(0, index),
        updatedScreenplaySlide,
        ...currentVal.screenplay_slides.slice(index + 1),
      ],
    }));
  };

  const handleSaveAudio = (audios: any) => {
    const bgMusicAudio = audios.filter((audio: any) => audio.type === 'MUSIC')[0].src;

    setInternalVideoData({
      ...internalVideoData,
      video_data: {
        ...internalVideoData.video_data,
        audios,
      },
      bg_music: {
        ...internalVideoData.bg_music,
        src: bgMusicAudio,
      },
    });
  };

  const onSubtitleToggle = () => {
    let updatedComponents;
    if (internalVideoData.subtitles_enabled) {
      updatedComponents = internalVideoData.video_data.components.filter((component: any) => component.type !== 'SUBTITLES');
    } else {
      updatedComponents = cloneDeep(responseData.output.video_data.components);
    }

    setInternalVideoData({
      ...internalVideoData,
      subtitles_enabled: !internalVideoData.subtitles_enabled,
      video_data: {
        ...internalVideoData.video_data,
        components: updatedComponents,
      },
    });
  };

  const handleDownloadClick = async () => {
    if (isDownloading) {
      return;
    }

    setIsDownloading(true);
    await downloadVideo(responseData.content_id, responseData.output.download_links?.media);
    setIsDownloading(false);
  };

  const handleCancel = () => {
    navigate(`/pages/${responseData.page_id}`);
  };

  const handleSeekValue = (index: number) => {
    let currentVal = 0;
    internalVideoData.video_data.slides.map((slide: any, slideIndex: number) => {
      if (index > slideIndex) {
        currentVal += slide.duration;
      }
      passSeekValue(currentVal + 0.1);
    });
  };

  return (
    <div className="overflow-y-auto h-full minimal-scrollbar pr-4">
      <EditAudioModal
        audios={internalVideoData.video_data.audios}
        onClose={() => setOpenEditAudioModal(false)}
        onSave={handleSaveAudio}
        isOpen={openEditAudioModal}
        allowTypes={[AudioType.Music]}
      />
      <div className="flex flex-col gap-6 justify-center xl:flex-row sticky z-40 bg-primary-light dark:bg-[#131314] top-0 xl:justify-between items-center pb-6">
        <div className="flex gap-2">
          <Button
            onClick={() => setOpenEditAudioModal(true)}
            color="primary"
            className="bg-tertiary-light dark:bg-primary-dark text-white"
            isIconOnly
            style={{
              borderRadius: '50%',
              padding: '0',
            }}
          >
            <Icon icon="iconoir:tiktok" style={{ fontSize: '1.5rem' }} />
          </Button>
          <Button
            onClick={() => onSubtitleToggle()}
            color="primary"
            className="bg-tertiary-light dark:bg-primary-dark text-white"
            isIconOnly
            style={{
              borderRadius: '50%',
              padding: '0',
            }}
          >
            <Icon
              icon={internalVideoData.subtitles_enabled ? 'iconoir:closed-captions-tag-solid' : 'iconoir:closed-captions-tag'}
              style={{ fontSize: '1.5rem' }}
            />
          </Button>
        </div>
        <div className="flex gap-2">
          <Button
            onClick={handleCancel}
            color="primary"
            className="bg-tertiary-light dark:bg-primary-dark text-white"
            isIconOnly
            style={{
              padding: '0',
            }}
          >
            <Icon
              icon="lets-icons:refund-back"
              style={{ fontSize: '1.5rem' }}
            />
          </Button>
          <ShareButton url={`${window.origin}/pages/${responseData?.page_id}`} key={1} isIconOnly />
          <Button
            onClick={handleDownloadClick}
            color="primary"
            className="bg-tertiary-light dark:bg-primary-dark text-white"
            isIconOnly
            isLoading={isDownloading}
            style={{
              padding: '0',
            }}
          >
            <img className="invert" src="/icons/download-icon.svg" alt="" />
          </Button>
          <Button
            className="dark:text-primary-dark bg-tertiary-light text-primary-light rounded-xl text-sm font-medium dark:!bg-[#FFFFFF]"
            onClick={handleSaveClick}
            startContent={isProcessing ? null : (
              <img className="invert dark:invert-0" src="/icons/tick-2.svg" />
            )}
            isLoading={isProcessing}
          >
            {isProcessing ? 'Updating' : 'Save'}
          </Button>
        </div>
      </div>
      {internalVideoData.video_data.slides.map((slide: any, index: number) => (
        <Fragment key={`${slide.duration}-${index}`}>
          <EditSlideItemProduct
            slide={slide}
            index={index}
            activeIndex={activeIndex}
            seekToSlide={handleSeekValue}
            onDelete={handleDeleteSlide}
            onDuplicateSlide={handleDuplicateSlide}
            onSave={handleSaveSlide}
            unhydratedSlideLayout={internalVideoData.unhydrated_slides[index]}
            screenplaySlide={internalVideoData.screenplay_slides[index]}
            showSlideEditingModal={editSlideIndex === index}
            setEditSlideIndex={setEditSlideIndex}
            isProcessing={isProcessing}
            setIsProcessing={setIsProcessing}
            updateVideo={updateVideo}
            fetchContent={regenerate}
            showChatEditOption={showSlideChatEditOption}
          />
          <Spacer y={4} />
        </Fragment>
      ))}
    </div>
  );
}

export default SlideItemsProduct;
