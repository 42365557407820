import { useState, useEffect } from 'react';

import RemotionPlayerContainer from './components/remotion-player-container';
import Chat from './components/chat';
import SlideItems from './components/slide-items';
import SlideItemsProduct from './components/slide-items-product';

export function EditVideoTogether({ videoId, responseData }: any) {
  const [internalVideoData, setInternalVideoData] = useState<any>(responseData.output);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [regenerate, setRegenerate] = useState<boolean>(false);
  const [showChat, setShowChat] = useState<boolean>(false);
  const [addNewSlideIndex, setAddNewSlideIndex] = useState<number | null>(null);
  const [editSlideIndex, setEditSlideIndex] = useState<number | null>(null);
  const [chatMessage, setChatMessage] = useState<string | null>(null);
  const [seekValue, setSeekValue] = useState<number | null>(null);

  const handleRegenerate = (value: boolean) => {
    if (value) {
      setRegenerate(true);
    }
  };

  const toggleView = () => {
    setShowChat(!showChat);
  };

  const handleSetChatMessage = (message: string) => {
    setChatMessage(message);
  };

  useEffect(() => {
    if (regenerate) {
      setRegenerate(false);
    }
  }, [regenerate]);

  useEffect(() => {
    if (showChat && chatMessage) {
      setChatMessage(null);
    }
  }, [showChat, chatMessage]);

  return (
    <div
      className="min-lg-height:h-[calc(100vh_-_72px)] h-[1350px] min-lg:h-[688px] overflow-hidden py-8 w-full flex flex-col items-center justify-center gap-6 min-lg:gap-6 min-lg:flex-row"
    >
      <RemotionPlayerContainer
        seekValue={seekValue}
        setChatMessage={handleSetChatMessage}
        videoId={videoId}
        responseData={responseData}
        internalVideoData={internalVideoData}
        setIsProcessing={setIsProcessing}
        setInternalVideoData={setInternalVideoData}
        regenerate={regenerate}
        addNewSlideIndex={addNewSlideIndex}
        setAddNewSlideIndex={setAddNewSlideIndex}
        editSlideIndex={editSlideIndex}
        setEditSlideIndex={setEditSlideIndex}
      />
      <div className="flex flex-col grow min-lg:w-fit w-full h-full overflow-hidden">
        <div className="flex font-bold dark:text-secondary-dark text-secondary-light mb-6 gap-2 justify-center items-center">
          <div>Scenes</div>
          <div className="dark:bg-secondary-dark bg-sectionBackground-light w-14 h-7 flex items-center px-1 rounded-full" onClick={toggleView}>
            <div className={`dark:bg-primary-dark bg-tertiary-light w-5 h-5 rounded-full duration-500 ${showChat ? 'translate-x-7' : 'translate-x-0'}`} />
          </div>
          <div>Chat</div>
        </div>
        {showChat ? (
          <Chat
            regenerate={handleRegenerate}
            setIsProcessing={setIsProcessing}
            isProcessing={isProcessing}
            responseData={responseData}
            videoId={videoId}
            chatMessage={chatMessage || ''}
          />
        ) : internalVideoData.type === 'product_video' ? (
          <SlideItemsProduct
            internalVideoData={internalVideoData}
            setInternalVideoData={setInternalVideoData}
            videoId={videoId}
            responseData={responseData}
            isProcessing={isProcessing}
            setIsProcessing={setIsProcessing}
            regenerate={handleRegenerate}
            passSeekValue={setSeekValue}
            editSlideIndex={editSlideIndex}
            setEditSlideIndex={setEditSlideIndex}
          />
        ) : (
          <SlideItems
            internalVideoData={internalVideoData}
            setInternalVideoData={setInternalVideoData}
            videoId={videoId}
            responseData={responseData}
            isProcessing={isProcessing}
            setIsProcessing={setIsProcessing}
            regenerate={handleRegenerate}
            addNewSlideIndex={addNewSlideIndex}
            setAddNewSlideIndex={setAddNewSlideIndex}
            passSeekValue={setSeekValue}
            editSlideIndex={editSlideIndex}
            setEditSlideIndex={setEditSlideIndex}
          />
        )}
      </div>
    </div>
  );
}
